import React, { useState } from "react";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Box,
    Flex,
    useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const EnterEmail = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const handleEmailSent = async () => {
        try {
            setLoading(true);
            const response = await axios.post("https://bharatapi.exploreanddo.com/api/forgot-password-mail-one",{
                email:email
            });
            if (response.data.success) {
                toast({
                    position: "top",
                    title: "Reset Password Email Sent",
                    description: `${email} received an email for password reset`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                //navigate("/reset-password");
            } else {
                toast({
                    position: "top",
                    title: "Sending Email Failed",
                    description: "Account not Found",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Forget Password failed:", error);
            toast({
              position: "top",
              title: "Sending Email Failed! Account not Found",
              description: error.message || "An error occurred",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
        }finally {
            setLoading(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleEmailSent();
        }
    };

    return (
        <Flex
            minHeight="100vh"
            alignItems="center"
            justifyContent="center"
            px={{ base: 4, sm: 6, md: 8 }}
        >
            <VStack spacing={4} align="stretch" w="100%" maxW="md">
                <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">

                    <FormControl mt={4} id="email">
                        <FormLabel>Enter Your Registered Email</FormLabel>
                        <Input
                            type="email"
                            placeholder="Enter your registered email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            onKeyPress={handleKeyPress}
                        />
                    </FormControl>


                    {loading ? (<Button
                        mt={4}
                        onClick={handleEmailSent}
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                        w="100%"
                        isLoading
                        loadingText='Reset Email Sending'
                    >Send Reset Link
                    </Button>) : (<Button
                        mt={4}
                        onClick={handleEmailSent}
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                        w="100%"
                    >
                        Send Reset Link
                    </Button>)}


                </Box>
            </VStack>
        </Flex>
    );
};

export default EnterEmail;
