import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    if (new_password !== confirm_password) {
      toast({
        position: "top",
        title: "Password Mismatch",
        description: "New Password and Confirm Password must match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        "https://bharatapi.exploreanddo.com/api/reset-password-one/1",
        {
          new_password: new_password,
          confirm_password: confirm_password,
        }
      );
      if (response.data.success) {
        toast({
          position: "top",
          title: "Password Reset Successfull",
          description: `Redirecting you to login page`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast({
          position: "top",
          title: "Password Reset Failed",
          description: "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Password Reset Failed", error);
      toast({
        position: "top",
        title: "Password Reset Failed! Try again",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleResetPassword();
    }
  };

  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      px={{ base: 4, sm: 6, md: 8 }}
    >
      <VStack spacing={4} align="stretch" w="100%" maxW="md">
        <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">
          <FormControl mt={4} id="new_password">
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              placeholder="Set your new password"
              value={new_password}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              onKeyPress={handleKeyPress}
            />
          </FormControl>
          <FormControl mt={4} id="confirm_password">
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              placeholder="Confirm your new password"
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              onKeyPress={handleKeyPress}
            />
          </FormControl>

          {loading ? (
            <Button
              mt={4}
              onClick={handleResetPassword}
              bgGradient="linear(to-r, teal.300,  teal.100)"
              _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
              w="100%"
              isLoading
              loadingText="Password Reseting"
            >
              Reset Password
            </Button>
          ) : (
            <Button
              mt={4}
              onClick={handleResetPassword}
              bgGradient="linear(to-r, teal.300,  teal.100)"
              _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
              w="100%"
            >
              Reset Password
            </Button>
          )}
        </Box>
      </VStack>
    </Flex>
  );
};

export default ResetPassword;
