import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Input,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Button,
  Tooltip,
  ListItem,
  List,
  useToast,
  ListIcon,
  Image,
} from "@chakra-ui/react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import html2pdf from "html2pdf.js";
import { MdCheckCircle } from "react-icons/md";
import { RiDeleteBin2Line } from "react-icons/ri";
import logo from "../assets/logo.jpg";
import { format } from "date-fns";

const GenerateInvoice = () => {
  const toast = useToast();
  const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
  const [cart, setCart] = useState(storedCart);
  const [invoiceItems, setInvoiceItems] = useState([storedCart]);
  //console.log("invoiceItems", invoiceItems)
  const [biller_name, setBillerName] = useState("");
  const [biller_mobile, setBillerMobile] = useState("");
  const [biller_address, setBillerAddress] = useState("");
  const [biller_city, setBillerCity] = useState("");
  const [biller_state, setBillerState] = useState("");
  const [invoice_no, setInvoiceNo] = useState("");

  const [invoice_date, setInvoiceDate] = useState("");
  console.log("invoice_date", invoice_date);
  const [quantityError, setQuantityError] = useState(null);
  // Add this state variable
  const [showQuantityButtons, setShowQuantityButtons] = useState(true);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [showUniqueCode, setShowUniqueCode] = useState(false);

  // useEffect(() => {
  //   setInvoiceItems(
  //     cart.map((item) => ({ ...item, quantity: 0, rate: item.rate || 0 }))
  //   );
  //   localStorage.setItem("cart", JSON.stringify(cart));
  // }, [cart]);

  // const handleQuantityChange = (index, value) => {
  //   const updatedItems = [...invoiceItems];
  //   const cartItem = cart.find((item) => item.id === updatedItems[index].id);
  //   if (value > cartItem.quantity) {
  //     setQuantityError(`Only ${cartItem.quantity} quantity in Stock`);
  //   } else {
  //     setQuantityError(null);
  //     updatedItems[index] = {
  //       ...updatedItems[index],
  //       quantity: parseInt(value, 10) || 0,
  //     };
  //     setInvoiceItems(updatedItems);
  //   }
  // };

  useEffect(() => {
    setInvoiceItems(
      cart.map((item) => ({ ...item, quantity: 1, rate: item.rate || 0 }))
    );
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const handleQuantityChange = (index, value) => {
    const updatedItems = [...invoiceItems];
    const cartItem = cart.find((item) => item.id === updatedItems[index].id);
    if (value > cartItem.quantity) {
      setQuantityError(`Only ${cartItem.quantity} quantity in Stock`);
    } else {
      setQuantityError(null);
      updatedItems[index] = {
        ...updatedItems[index],
        quantity: parseInt(value, 10) || 0,
      };
      setInvoiceItems(updatedItems);
    }
  };

  const handlePriceChange = (index, value) => {
    const updatedItems = [...invoiceItems];
    updatedItems[index] = {
      ...updatedItems[index],
      rate: parseFloat(value) || 0,
    };
    setInvoiceItems(updatedItems);
  };
  const calculateTotal = () => {
    return invoiceItems.reduce(
      (total, item) => total + (item.quantity || 1) * item.rate,
      0
    );
  };

  const [salesTaxPercentage, setSalesTaxPercentage] = useState(14);
  console.log("salesTaxPercentage", salesTaxPercentage);
  const calculateWithTax = () => {
    const subTotal = invoiceItems.reduce(
      (total, item) => total + item.quantity * item.rate,
      0
    );
    const salesTax = (salesTaxPercentage / 100) * subTotal;
    return salesTax;
  };

  const calculateTotalWithTax = () => {
    const subTotal = invoiceItems.reduce(
      (total, item) => total + item.quantity * item.rate,
      0
    );
    // const salesTax = 0.1 * subTotal;
    const salesTax = (salesTaxPercentage / 100) * subTotal;
    const total = subTotal + salesTax;
    return total;
  };

  function generateRandomCode(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  }

  const uniqueCode = generateRandomCode(8);
  //console.log("uniqueCode",uniqueCode)

  const [valueofData, setValueOfData] = useState("");
  //console.log("valueofData",valueofData)

  const handleGenerateInvoice = () => {
    if (biller_name === "" && biller_mobile === "" && invoice_date === "") {
      toast({
        position: "top",
        title: "Fill the biller Details",
        description: `Biller details are required for generating invoice`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setShowQuantityButtons(false);
      setShowActionButtons(false);
      setShowUniqueCode(true);
      const pdfFileName = `${uniqueCode}_Invoice.pdf`;
      const formattedInvoiceDate = format(new Date(invoice_date), "dd-MM-yyyy");
      // console.log("formattedInvoiceDate",formattedInvoiceDate)
      const invoiceDetails = {
        biller_name,
        biller_mobile,
        biller_address,
        biller_city,
        biller_state,
        invoice_no: uniqueCode,
        invoice_date,
        gst_in_percent: 0,
      };
      const apiEndpoint =
        "https://bharatapi.exploreanddo.com/api/save-invoice-details";
      const requestBody = {
        ...invoiceDetails,
        cart: invoiceItems.map((item) => ({
          id: item.id,
          order_id: item.order_id,
          name: item.name,
          quantity: item.quantity,
          rate: item.rate,
        })),
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };
      fetch(apiEndpoint, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //console.log("Invoice generated successfully:", data);
          if (!data.success) {
            toast({
              position: "top",
              title: "Fill the biller Details",
              description: `Biller details are required for generating invoice`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            setValueOfData(invoiceDetails.invoice_no);
            toast({
              position: "top",
              title: "Invoice Generated Successfully",
              description: `You can print your invoice from download`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setBillerName(invoiceDetails.biller_name);
            setBillerAddress(invoiceDetails.biller_address);
            setBillerMobile(invoiceDetails.biller_mobile);
            setBillerCity(invoiceDetails.biller_city);
            setBillerState(invoiceDetails.biller_state);
            setInvoiceDate(invoiceDetails.invoice_date);
            setTimeout(() => {
              var element = document.getElementById("element-to-print");
              html2pdf(element, { filename: pdfFileName });
            }, 2000);
          }
          setBillerName(invoiceDetails.biller_name);
          setBillerAddress(invoiceDetails.biller_address);
          setBillerMobile(invoiceDetails.biller_mobile);
          setBillerCity(invoiceDetails.biller_city);
          setBillerState(invoiceDetails.biller_state);
          setInvoiceDate(invoiceDetails.invoice_date);
        })
        .catch((error) => {
          console.error("Error generating invoice:", error);
        });
    }
  };

  const removeFromCart = (itemToRemove) => {
    const updatedCart = cart.filter((item) => item.id !== itemToRemove.id);
    setCart(updatedCart);
    setInvoiceItems(
      updatedCart.map((item) => ({
        ...item,
        quantity: 0,
        rate: item.rate || 0,
      }))
    );
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    toast({
      position: "top",
      title: "Item removed from Cart",
      description: `Item added is now removed from cart`,
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <Box
          p={20}
          paddingX={{ base: 2, sm: 4, md: 8, lg: 12, xl: 16 }}
          paddingY={{ base: 2, sm: 4, md: 8, lg: 12, xl: 16 }}
          id="element-to-print"
        >
          <Flex justifyContent={"center"}>
            <Heading fontWeight={"bold"} as="h3" size="lg">
              Invoice
            </Heading>
          </Flex>
          <Flex justifyContent={"space-between"} p={5}>
            <Box textAlign={"start"}>
              <Heading fontWeight={"bold"} as="h4" size="md">
                Bharat Enterprises
              </Heading>
              <Flex justifyContent={"flex-start"} gap={2}>
                <Text fontWeight={"bold"}></Text>{" "}
              </Flex>
              <Flex justifyContent={"flex-start"} gap={2}>
                <Text fontWeight={"bold"}>Address:</Text>
              </Flex>
              <Text>Sy No: GR P.NO 7-231/2</Text>
              <Text>Beside Indane Petrol Pump</Text>
              <Text>Shant Nagar Bhankur</Text>
              <Text>TQ Shahabad, 585228</Text>
              <Flex justifyContent={"flex-start"} gap={2}>
                <Text fontWeight={"bold"}>Phone No:</Text> 9620819047
              </Flex>
              <Text>7795746499, 8904915905</Text>
            </Box>
            <Box>
              <Image
                src={logo}
                alt="Logo"
                boxSize="150px"
                objectFit="contain"
              />
            </Box>
          </Flex>
          <Box>
            <Flex justifyContent={"space-between"} p={5}>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} mb={2}>
                  Bill To :
                </Text>

                <FormControl id="biller_name">
                  <Input
                    type="text"
                    placeholder="Biller Name"
                    value={biller_name}
                    onChange={(e) => setBillerName(e.target.value)}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                {/* <FormControl id="biller_phone">
                  <Input
                    type="text"
                    inputMode="numeric"
                    placeholder="Biller Mobile"
                    value={biller_mobile}
                    onChange={(e) => setBillerMobile(e.target.value)}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                    pattern="[0-9]*"
                    maxLength={10}
                  />
                </FormControl> */}
                <FormControl id="biller_phone">
                  <Input
                    type="text"
                    inputMode="tel" 
                    placeholder="Biller Mobile"
                    value={biller_mobile}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                      setBillerMobile(e.target.value);
                    }}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                    maxLength={10}
                  />
                </FormControl>

                <FormControl id="biller_address">
                  <Input
                    type="text"
                    placeholder="Biller Address"
                    value={biller_address}
                    onChange={(e) => setBillerAddress(e.target.value)}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                <FormControl id="biller_city">
                  <Input
                    type="text"
                    placeholder="Biller City"
                    value={biller_city}
                    onChange={(e) => setBillerCity(e.target.value)}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                <FormControl id="order_id">
                  <Input
                    type="text"
                    placeholder="Biller State"
                    value={biller_state}
                    onChange={(e) => setBillerState(e.target.value)}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
              </Box>
              <Box pt={5}>
                {valueofData && (
                  <InputGroup size="sm">
                    <InputLeftAddon style={{ marginRight: 10 }}>
                      Invoice No
                    </InputLeftAddon>
                    <Input
                      type="tel"
                      placeholder="Invoice Number"
                      focusBorderColor="green.400"
                      value={valueofData}
                      bgGradient="linear(to-r, teal.300,  teal.100)"
                      _hover={{
                        bgGradient: "linear(to-r, teal.100,  teal.400)",
                      }}
                    />
                  </InputGroup>
                )}
                <InputGroup size="sm">
                  <InputLeftAddon>Invoice Date</InputLeftAddon>
                  <Input
                    type="date"
                    placeholder="Invoice Date"
                    focusBorderColor="green.400"
                    value={invoice_date}
                    onChange={(e) => setInvoiceDate(e.target.value)}
                    required
                    bgGradient="linear(to-r, teal.300,  teal.100)"
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  />
                </InputGroup>
              </Box>
            </Flex>
            <Table variant="simple" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th style={{ textAlign: "center" }}>Name</Th>
                  <Th style={{ textAlign: "center" }}>HSN/SAC</Th>

                  <Th style={{ textAlign: "center" }} isNumeric>
                    Quantity
                  </Th>
                  <Th style={{ textAlign: "center" }} isNumeric>
                    Price
                  </Th>
                  <Th style={{ textAlign: "center" }} isNumeric>
                    Total
                  </Th>
                  {showActionButtons && (
                    <Th style={{ textAlign: "center" }}>Action</Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {cart.map((item, index) => (
                  <Tr key={item.id}>
                    <Td style={{ textAlign: "center" }}>{item.name}</Td>
                    <Td style={{ textAlign: "center" }}>{item.order_id}</Td>
                    <Td style={{ textAlign: "center" }} isNumeric>
                      <Flex alignItems="center" gap={3}>
                        <Tooltip
                          label={quantityError}
                          isOpen={quantityError !== null}
                        >
                          <Input
                            w={150}
                            type="number"
                            value={invoiceItems[index]?.quantity || 1}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                        </Tooltip>
                        {showQuantityButtons && (
                          <Flex flexDirection={"column"} gap={1}>
                            <Button
                              size="xs"
                              onClick={() =>
                                handleQuantityChange(
                                  index,
                                  (invoiceItems[index]?.quantity || 1) + 1
                                )
                              }
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              +
                            </Button>
                            <Button
                              size="xs"
                              onClick={() =>
                                handleQuantityChange(
                                  index,
                                  Math.max(
                                    (invoiceItems[index]?.quantity || 1) - 1,
                                    0
                                  )
                                )
                              }
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              -
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </Td>

                    <Td style={{ textAlign: "center" }} isNumeric>
                      <Input
                        w={150}
                        type="number"
                        value={invoiceItems[index]?.rate || item.rate}
                        onChange={(e) =>
                          handlePriceChange(index, e.target.value)
                        }
                      />
                    </Td>
                    <Td style={{ textAlign: "center" }} isNumeric>
                      Rs{" "}
                      {(invoiceItems[index]?.quantity || 1) *
                        (invoiceItems[index]?.rate || item.rate)}
                    </Td>
                    {showActionButtons && (
                      <Td
                        style={{ textAlign: "center" }}
                        onClick={() => removeFromCart(item)}
                      >
                        <Button size={"xs"} color="red">
                          <RiDeleteBin2Line />
                        </Button>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex
              justifyContent={"flex-end"}
              flexDirection={"column"}
              alignItems={"end"}
            >
              <Flex
                justifyContent={"space-around"}
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={300}
                mt={5}
              >
                <Text textAlign={"start"} fontWeight={"bold"}>
                  Total:
                </Text>
                <Text textAlign={"end"} fontWeight={"bold"}>
                  Rs {calculateTotal().toFixed(2)}
                </Text>
              </Flex>
            </Flex>
            <Text textAlign={"start"} fontWeight={"bold"}>
              Note:{" "}
            </Text>

            <List spacing={3} textAlign={"start"}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                It was great doing business with you
              </ListItem>
            </List>
            <Text textAlign={"start"} fontWeight={"bold"} mt={5}>
              Terms & Conditions:{" "}
            </Text>

            <List spacing={3} textAlign={"start"}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Please review the bill before leaving the counter
              </ListItem>
            </List>
          </Box>
        </Box>
        <Flex justifyContent={"center"}>
          <Button
            mt={4}
            onClick={handleGenerateInvoice}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            w="40%"
            fontWeight={"bold"}
          >
            Generate Invoice
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default GenerateInvoice;
